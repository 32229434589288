import { postBodyRequest } from '@/utils/axios'

// 小程序下单列表
export function page (params) {
  return postBodyRequest('/trans/applet/order/page', params)
}

// 小程序订单接单
export function receive (params) {
  return postBodyRequest('/trans/applet/order/receive', params)
}

// 用户小程序下单列表
export function userPage (params) {
  return postBodyRequest('/market/applet-back/order/page', params)
}

// 小程序订单抢单
export function changeSalesman (params) {
  return postBodyRequest('/trans/applet/order/change-salesman', params)
}
