import { postBodyRequest } from '@/utils/axios'
// 小程序用户分页
export function page (params) {
  return postBodyRequest('/market/applet-back/user/page', params)
}
// 企业认证
export function configCustomer (params) {
  return postBodyRequest('/market/applet-back/user/config/customer', params)
}
// 设置推广类型
export function configPromoter (params) {
  return postBodyRequest('/market/applet-back/user/config/promoter', params)
}
// 绑定业务员
export function configSalesman (params) {
  return postBodyRequest('/market/applet-back/user/config/salesman', params)
}
// 推广人分页
export function promoterPage (params) {
  return postBodyRequest('/market/applet-back/user/promoter/page', params)
}

// 推广人详情分页
export function promoterDetailPage (params) {
  return postBodyRequest('/market/applet-back/user/promoter/detail/page', params)
}

// 拉黑
export function userIsBlack (params) {
  return postBodyRequest('/market/applet-back/user/isBlack', params)
}
